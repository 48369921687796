<template>
	<div>
		<div class="pt40 pl30 pr30">
			<div class="flex alcenter space">
				<div class="ft20 cl-black cl-main ftw500">消息通知</div>
				<a-button @click="back()">返回</a-button>
			</div>
			<div class="mt20">
				<div class="bg-w pd30" style="min-height: 700px;">
					<a-collapse v-model="activeKey" :expand-icon-position="expandIconPosition">
						<a-collapse-panel key="1" header="我要有新人必看！三步教您开启门店会员+营销之旅！">
							<p>我要有新人必看！三步教您开启门店会员+营销之旅！我要有新人必看！三步教您开启门店会员+营销之旅！我要有新人必看！三步教您开启门店会员+营销之旅！我要有</p>
							<div slot="extra">2020-12-31</div>
						</a-collapse-panel>
						<a-collapse-panel key="2" header="我要有新人必看！三步教您开启门店会员+营销之旅！">
							<p>我要有新人必看！三步教您开启门店会员+营销之旅！我要有新人必看！三步教您开启门店会员+营销之旅！我要有新人必看！三步教您开启门店会员+营销之旅！我要有</p>
							<div slot="extra">2020-12-31</div>
						</a-collapse-panel>
						<a-collapse-panel key="3" header="我要有新人必看！三步教您开启门店会员+营销之旅！">
							<p>我要有新人必看！三步教您开启门店会员+营销之旅！我要有新人必看！三步教您开启门店会员+营销之旅！我要有新人必看！三步教您开启门店会员+营销之旅！我要有</p>
							<div slot="extra">2020-12-31</div>
						</a-collapse-panel>
					</a-collapse>
					
					<div class="flex center mt20">
						<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
					</div>
				</div>
			</div>
			
			
		</div>

	</div>
</template>

<script>
	import {listMixin} from '../../common/mixin/list.js';
	export default {
		mixins:[listMixin],
		data() {
			return {
				activeKey: [],
				expandIconPosition: 'right',
			}
		},
		created() {
			console.log(1);
		},
		methods: {
			back(){
				this.$router.go(-1);
			}
		}
	}
</script>

<style>
</style>
